.App {
  text-align: center;
}

.App-logo {
  height: 80px;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer {
  background-color: #282c34;
  padding: 20px;
  color:white;
  text-align: center;
}

.footer a {
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
